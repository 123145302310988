import "theme/global.css"

import React from "react"

import { Header, LayoutContainer, Footer } from "components"

import GlobalStyle from "theme/GlobalStyle"

export default ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Header />
      <main>
        <LayoutContainer>{children}</LayoutContainer>
      </main>
      <Footer />
    </>
  )
}
